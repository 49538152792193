import React, { useState } from 'react';
import {
  Table,
  Pagination,
  Spin,
  Dropdown,
  Menu,
  Tag,
  Tabs,
  Modal,
  Button,
  Row,
  Col,
  Input,
  Radio,
  Select,
} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { queryClient } from 'rc-query';
import { useGetAllUsers, useBlockUserMutation } from 'rc-query/userHooks';
import { getAdmin, getBackoffice } from 'helpers/localStorage';
import { DEFAULT_PAGE_SIZE } from 'constants/config';
import ExportFile from 'components/ExportFile';
// import { renderTags } from 'helpers/utils';

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

const DataTable = ({ pageSize, hideTabs, hideActivityLogBtn }) => {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: pageSize || DEFAULT_PAGE_SIZE,
  });

  const [showBlockEntityBox, setShowBlockEntityBox] = useState(false);
  const [entityDetail, setEntityDetail] = useState({});
  const [entityType, setEntityType] = useState('individual');

  const adminUser = getAdmin();
  const backOffice = getBackoffice();
  const apiKey = backOffice?.data?.name;
  const adminToken = adminUser?.token;

  const blockUserMutation = useBlockUserMutation();

  const { isLoading, data } = useGetAllUsers({
    api_key: backOffice?.data?.name,
    token: adminUser?.token,
    ...filters,
  });

  const entitiesData = entityType === 'individual' ? data?.users?.users : data?.businesses?.business;
  const usersData = entitiesData?.data || [];
  const totalUsers = entitiesData?.total || 0;
  const currentPage = entitiesData?.current_page || 0;

  const stats = entityType === 'individual' ? data?.users?.stats || {} : data?.businesses?.stats;
  const all = stats?.total || 0;
  const active = stats?.active || 0;
  const pending = stats?.pending || 0;
  const blocked = stats?.blocked || 0;

  const renderUserStatus = (status, is_blocked) => {
    if (entityType === 'business' && is_blocked === 'true') {
      return <Tag color="red">Blocked</Tag>;
    } else {
      switch (status) {
        case 'active':
          return <Tag color="green">Active</Tag>;
        case 'verified':
          return <Tag color="green">Verified</Tag>;
        case 'inprogress':
          return <Tag color="blue">In Progress</Tag>;
        case 'pending':
          return <Tag color="orange">Pending</Tag>;
        case 'cancelled':
          return <Tag color="red">Cancelled</Tag>;
        case 'blocked':
          return <Tag color="red">Blocked</Tag>;
        case 'deactive':
          return <Tag color="red">Deactivated</Tag>;
        case 'failed':
          return <Tag color="red">Failed</Tag>;
        default:
          return null;
      }
    }
  };

  const renderKYStatus = (status) => {
    switch (status) {
      case 'verified':
        return <Tag color="green">Verified</Tag>;
      case 'pending':
        return <Tag color="orange">Pending</Tag>;
      case 'in_progress':
        return <Tag color="blue">In Progress</Tag>;
      case 'inprogress':
        return <Tag color="blue">In Progress</Tag>;
      case 'failed':
        return <Tag color="red">Failed</Tag>;
      default:
        return null;
    }
  };

  const setUserStatus = (userId, status) => {
    blockUserMutation
      .mutateAsync({
        api_key: apiKey,
        token: adminToken,
        user_id: `${userId}`,
        status,
        entityType,
      })
      .then(() => {
        queryClient.refetchQueries(['allUsers']);
        handleCancelBox();
      });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 180,
      render: (text, record) => (
        <Link to={`/entity/${entityType}/${record.id}`}>
          <span className="capitalize">{record.name}</span>
        </Link>
      ),
      // ...TableColumnTextFilterConfig(),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => (
        <div className="capitalize ellipsis">
          <span title={record.email}>{record.email}</span>
        </div>
      ),
      sorter: true,
      width: 270,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      width: 180,
      render: (text, record) => <span>{record.phone_number || 'NA'}</span>,
    },
    {
      title: 'Reg Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      width: 150,
      render: (text, record) => moment(record.created_at).format('MMM DD, YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: 100,
      render: (text, record) => <span>{renderUserStatus(record.status, record.is_blocked)}</span>,
    },
    {
      title: entityType === 'individual' ? 'KYC Status' : 'KYB Status',
      dataIndex: entityType === 'individual' ? 'KYC status' : 'KYB status',
      key: entityType === 'individual' ? 'kyc_status' : 'kyb_status',
      sorter: true,
      width: 140,
      render: (text, record) => (
        <span>{renderKYStatus(entityType === 'individual' ? record.kyc_status : record.kyb_status)}</span>
      ),
    },
    // {
    //   title: 'Persona Tags',
    //   dataIndex: 'persona_tags',
    //   key: 'persona_tags',
    //   width: 350,
    //   render: () => renderTags(['Eco Friendly', 'No car loan', 'Robo investor']),
    // },
    // {
    //   title: 'Audience',
    //   dataIndex: 'audience',
    //   key: 'audience',
    //   width: 350,
    //   render: () => <Link to="/audiences">Weekend warrior heloc</Link>,
    // },
    {
      title: 'Action',
      key: 'user_id',
      dataIndex: 'id',
      width: 100,
      fixed: 'right',

      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu key={`menu-${record.id}`}>
              <Menu.Item key={`send-${record.id}`}>
                <Link to={`/entity/${entityType}/${text}`}>View</Link>
              </Menu.Item>
              {(entityType === 'business' && record?.is_blocked === 'true') || record.status === 'blocked' ? (
                <Menu.Item key={`active-${record.id}`} onClick={() => setUserStatus(record.id, 'active')}>
                  <span className="green">Activate</span>
                </Menu.Item>
              ) : (
                <Menu.Item key={`block-${record.id}`} onClick={() => handleShowBlockEntityBox(record)}>
                  <span className="red">Block</span>
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <a className="ant-dropdown-link">
            <EllipsisOutlined />
          </a>
        </Dropdown>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setFilters({ ...filters, page });
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    // const name = tableFilters?.name?.[0]?.toString() || undefined;

    setFilters({
      ...filters,
      page: 1,
      per_page: pageSize || DEFAULT_PAGE_SIZE,
      sort_by_column: sorter?.field,
      sort_by_type: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const onSearch = (value) => {
    const name = value?.toString() || undefined;

    setFilters({
      ...filters,
      search: name,
      page: 1,
      per_page: pageSize || DEFAULT_PAGE_SIZE,
    });
  };

  const handleTabs = (key) => {
    setFilters(key !== 'all' ? { ...filters, status: key, entityType } : { ...filters, status: undefined, entityType });
  };

  const handleShowBlockEntityBox = (record) => {
    setEntityDetail(record);
    setShowBlockEntityBox(true);
  };

  const handleCancelBox = () => {
    setShowBlockEntityBox(false);
    setEntityDetail({});
  };

  const handleEntityTypeChange = (value) => {
    setEntityType(value);
    // setFilters({
    //   page: 1,
    //   per_page: DEFAULT_PAGE_SIZE,
    //   entityType: value,
    // });
  };

  return (
    <Spin spinning={isLoading}>
      {!hideTabs ? (
        <Row>
          <Col span={20}>
            <Tabs defaultActiveKey="0" left="true" size="large" onChange={handleTabs}>
              <TabPane onChange={() => handleTabs('all')} tab={`All (${all})`} key="all" />
              <TabPane onChange={() => handleTabs('active')} tab={`Active (${active})`} key="active" />
              <TabPane onChange={() => handleTabs('pending')} tab={`Pending (${pending})`} key="pending" />
              <TabPane onChange={() => handleTabs('blocked')} tab={`Blocked (${blocked})`} key="blocked" />
            </Tabs>
          </Col>

          <Col span={4}>
            <Radio.Group>
              <Select defaultValue="individual" onChange={handleEntityTypeChange} style={{ width: 200 }}>
                <Option value="individual">Individual</Option>
                <Option value="business">Business</Option>
              </Select>
            </Radio.Group>
          </Col>
        </Row>
      ) : null}
      <div>
        <div className="searchBox" style={{ margin: '15px 0px' }}>
          <Search placeholder="Search entities" onSearch={onSearch} allowClear style={{ width: 300 }} />
          {!hideActivityLogBtn ? (
            <div>
              <Button type="primary" size="large" style={{ marginRight: '10px' }}>
                <Link to={{ pathname: '/activity_log', state: { from: '/entities' } }}>Activity Log</Link>
              </Button>
              <ExportFile
                data_type={entityType === 'individual' ? 'individuals' : 'businesses'}
                status={filters.status || 'all'}
              />
            </div>
          ) : null}
        </div>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          pagination={false}
          dataSource={usersData}
          onChange={handleTableChange}
          scroll={{
            x: 1700,
          }}
        />
        {totalUsers > 0 ? (
          <div className="pagination">
            <Pagination
              showLessItems
              defaultPageSize={DEFAULT_PAGE_SIZE}
              total={totalUsers}
              showSizeChanger={false}
              current={currentPage}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>

      <Modal
        width={450}
        title="Block Entity"
        visible={showBlockEntityBox}
        onCancel={handleCancelBox}
        footer={false}
        className="blockEntityModal"
        key={`block-entity-modal-${entityDetail?.id}`}
      >
        <Row style={{ marginTop: '20px' }}>
          <Col span={24}>
            <p className="descriptionText">
              You are about to permanently block [{entityDetail?.name}], Are you sure about this?
            </p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '22px' }}>
          <Col span={24}>
            <Button
              className="actionBtn"
              type="danger"
              size="large"
              style={{ width: '100%' }}
              onClick={() => setUserStatus(entityDetail.id, 'blocked')}
            >
              Block [{entityDetail?.name}]
            </Button>
          </Col>
          <Col span={24}>
            <Button
              className="actionBtn"
              type="default"
              size="large"
              style={{ width: '100%' }}
              onClick={handleCancelBox}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
    </Spin>
  );
};

export default DataTable;
